import React from 'react'
import Button from 'react-bootstrap/Button'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  team,
  team_title,
  team_btn,
  team_list,
  team_pic,
  team_item,
  team_name,
  team_position,
} from './style.module.scss'

export const Team = () => {
  const data = useStaticQuery(graphql`
    {
      allTeamJson(filter: { slug: { in: ["wansu-lee", "yongkoo-cho", "kyongsok-chong"] } }) {
        edges {
          node {
            id
            slug
            name
            position
            photo {
              id
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 262, height: 300, quality: 100, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `)
  const dataSort = data.allTeamJson.edges.sort(({ node: a }, { node: b }) => a.id - b.id)
  return (
    <section className={team}>
      <div>
        <h2 className={team_title}>Our Team</h2>
        <p>
          Our team is comprised of experts from various fields such as law, tax, accounting and investment consulting,
          and with their onsite experience and knowledge about the local market, can come up with creative solutions for
          our clients in their critical moments.
        </p>
        <p>
          We also have the capacity to provide one-stop service in relation to almost any foreign investment projects in
          the region.  Based on our in-depth knowledge and understanding of local laws and markets of Southeast Asia, we
          believe that our clients, especially the foreign investors in need of full-range legal services, can stay
          confident with us in the region.
        </p>
        <Button variant="white" as={Link} className={team_btn} to="/team">
          See more
        </Button>
      </div>
      <div className={team_list}>
        {dataSort.map(({ node: { slug, name, position, photo } }) => (
          <Link to={`/team/${slug}`} className={team_item} key={name}>
            <span className={team_pic}>
              <GatsbyImage image={photo.childImageSharp.gatsbyImageData} alt={name} style={{ display: 'block' }} />
            </span>
            <p className={team_position}>{position}</p>
            <p className={team_name}>{name}</p>
          </Link>
        ))}
      </div>
    </section>
  )
}
