import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { hero, hero_title, hero_content } from './style.module.scss'

export const Hero = ({ location }) => {
  return (
    <section className={hero}>
      <StaticImage
        src="./img/diploma.svg"
        layout="constrained"
        width={513}
        height={327}
        alt="Diploma"
        quality={100}
        placeholder="tracedSVG"
      />
      <div className={hero_content}>
        <h1 className={hero_title}>LIWU focuses on providing efficient legal service</h1>
        <p>
          LIWU {location} Practice Group (“LIWU APG”) is an international network which specializes in legal and
          investment advisory services throughout Southeast Asia.
        </p>
        <p>
          Since our establishment in 2016, we have been growing rapidly and has now gained a recognition as one of the
          best partners in Southeast Asia as a result of our commitment towards our clients’ success.
        </p>
      </div>
    </section>
  )
}
