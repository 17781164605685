import * as React from 'react'
import { Container } from 'react-bootstrap'
import Layout from '~components/Layout'
import SEO from '~components/seo'
import { Hero } from './components/Hero'
import { Team } from './components/Team'

export const About = ({ location }) => {
  return (
    <Layout>
      <Container>
        <SEO title={`Liwu ${location}`} />
        <Hero location={location} />
        <Team />
      </Container>
    </Layout>
  )
}
